import React from "react";
import { Helmet } from "react-helmet";

//static
import index1 from '../images/index1.jpg'
import index2 from '../images/index2.jpg'
import index3 from '../images/index3.jpg'
import grillPakke from '../images/grill_pakke.jpg'
import bacon from '../images/bacon.jpg'
import facebookLogo from '../images/facebook_logo.png'
import foodSafetyReport from '../images/Banner_groen_847x274.gif'
import epBanner from '../images/EP_Email-banner.jpg'

//Components
import OpeningHoursTable from "../components/OpeningHoursTable";
import Slider from "../components/Slider";

const Forside = () => {
    const indexImages = [index1, index2, index3, grillPakke, bacon]

    return (
        <>
            <Helmet>
                <title>
                    Forside | Klippingeslagteren
                </title>
            </Helmet>
        <div className="flex flex-col-reverse mt-24 my-4 p-3 justify-center md:flex-row md:mt-40">
            <div className="w-full mx-0 md:w-9/12 md:mx-4">
                <Slider images={indexImages}/>
            </div>
            <div className="w-full mx-0 text-center mb-6 md:w-1/2 md:mx-4 md:mb-0">
                <div className='w-fit py-2 mx-auto mb-4 md:py-0'>
                    <OpeningHoursTable />
                </div>

                <div className="maps-wrapper">
                    <div className="maps-title">
                        <u>INFORMATION</u>
                    </div>
                    <div className="maps-content mb-4">
                        For bestillinger eller spørgsmål <br />
                        skriv eller ring gerne til os - også på vores lukkedage
                    </div>
                </div>

                <div className="other-wrapper">
                    <div className="logos">
                        <a
                            href="https://www.facebook.com/klippingeslagteren"
                            title="Følg os på Facebook"
                            target="_blank"
                        >
                            <img
                                src={facebookLogo}
                                alt=""
                                width="50"
                                height="50" />
                        </a>
                    </div>
                    <div className="logos">
                        <a href="http://www.findsmiley.dk/84736"
                           title="Se vores smiley rapport"
                           target="_blank"
                        >
                            <img
                                src={foodSafetyReport}
                                alt=""
                                width="150"
                                height="50" />
                        </a>
                    </div>
                    <div className="logos">
                        <a
                            href='https://www.cancer.dk/stoet-os/for-virksomheder/'
                            target="_blank"
                        >
                            <img
                                src={epBanner}
                                alt=""
                                width="160"
                                height="60" />
                        </a>
                    </div>
                </div>
                <div className='mt-4'>
                    <a href="https://www.google.dk/maps/place/Klippinge+Slagteren/@55.3474213,12.3225589,17z/data=!3m1!4b1!4m5!3m4!1s0x4652e479dbd275e1:0x81c87842d43f65ff!8m2!3d55.3474213!4d12.3247476"
                       target="_blank"
                    >
                    <div className="uppercase font-bold underline hover:text-red">
                        FIND VEJ
                    </div>
                    </a>
                </div>
            </div>
        </div>
            </>
    )
}

export default Forside