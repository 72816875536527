import React from 'react';
import '../styles/styles.css';

//Components
import Forside from "./Forside";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const isBrowser = typeof window !== "undefined"

const IndexPage = () => {

    if (isBrowser) {
        return (
            <>
                <Navbar />
                <Forside />
                <Footer />
            </>
        )

    }
    else {
        return
    }
}

export default IndexPage;

